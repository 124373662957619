import Lottie from "lottie-react"
import React, { useState, useEffect } from "react"

import bottomCurve from "../../images/bottom-curve-v2.svg"
import topCurve from "../../images/top-curve-v2.svg"
import fetchLottie from "../../utils/fetchLottie"

import SwitchbackContent from "./components/SwitchbackContent"
import {
  Wrapper,
  Section,
  Grid,
  HeadingWrapper,
  Heading,
  Subhead,
  SwitchbackContainer,
  SwitchbackImg,
  Curves,
} from "./styles/SwitchbackMulticolumn.styled"

const SwitchbackMulticolumn = ({ component }) => {
  const variant = component?.variant
  const background = component?.background
  const bgClass = background && background.replace(/ /g, "").toLowerCase()
  const [animation, setAnimation] = useState()

  useEffect(() => {
    if (component?.lottie) {
      fetchLottie(component?.lottie?.url, setAnimation)
    }
  }, [])

  return (
    <Wrapper variant={variant}>
      {variant && <Curves className="top" src={topCurve} alt="" />}
      <Section background={bgClass}>
        <Grid>
          <HeadingWrapper>
            {component?.heading && (
              <Heading background={bgClass}>{component?.heading}</Heading>
            )}
            {component?.subhead && (
              <Subhead background={bgClass}>{component?.subhead}</Subhead>
            )}
          </HeadingWrapper>
          <SwitchbackContainer>
            <SwitchbackContent component={component} />
            {component?.image?.url && !component?.lottie?.url && (
              <SwitchbackImg
                alt={component?.image?.alt || "Image"}
                src={component?.image?.url}
              />
            )}
            {component?.lottie?.url && (
              <Lottie
                className="animation"
                animationData={animation}
                delay={0}
                loop
              />
            )}
          </SwitchbackContainer>
        </Grid>
      </Section>
      {variant && <Curves className="bottom" src={bottomCurve} alt="" />}
    </Wrapper>
  )
}

export default SwitchbackMulticolumn
