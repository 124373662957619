import styled, { css } from "styled-components"

import { color } from "../../../atoms/colors"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  @media (max-width: 991.98px) {
    max-width: 100%;
  }
`

export const SecondaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Heading = styled.div`
  font-weight: 850;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: ${color.common.white};
  margin-bottom: 16px;
  ${(props) =>
    props?.background && props?.background === `lightgradient`
      ? css`
          color: ${color.oxfordBlue[900]};
        `
      : props?.background === `light`
      ? css`
          color: ${color.oxfordBlue[900]};
        `
      : css`
          color: ${color.common.white};
        `}
  @media (max-width: 575.98px) {
    font-size: 20px;
    line-height: 28px;
  }
`

export const Subhead = styled.div`
  font-weight: 350;
  font-size: 18px;
  line-height: 28px;
  color: ${color.copy[200]};
  ${(props) =>
    props?.background && props?.background === `lightgradient`
      ? css`
          color: ${color.copy[700]};
        `
      : props?.background === `light`
      ? css`
          color: ${color.copy[700]};
        `
      : css`
          color: ${color.copy[200]};
        `}
  @media (max-width: 575.98px) {
    font-size: 16px;
  }
`

export const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 24px;
  row-gap: 35px;
  margin-top: 36px;
  @media (max-width: 992px) {
    margin-right: 0px;
  }
`

export const Item = styled.div`
  color: ${color.common.white};
  .label {
    font-weight: 850;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 8px;
    @media (max-width: 575.98px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  ${(props) =>
    props?.background && props?.background === `lightgradient`
      ? css`
          color: ${color.copy[700]};
          .label {
            color: ${color.oxfordBlue[900]};
          }
        `
      : props?.background === `light`
      ? css`
          color: ${color.copy[700]};
          .label {
            color: ${color.oxfordBlue[900]};
          }
        `
      : css`
          color: ${color.copy[200]};
        `}
`

export const IconWrapper = styled.div`
  height: 56px;
  width: 56px;
  margin-bottom: 16px;
  img {
    height: auto;
    width: 100%;
  }
`
