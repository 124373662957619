import styled, { css } from "styled-components"

import { color } from "../../../atoms/colors"

export const Wrapper = styled.div`
  position: relative;
  margin-top: ${(props) => (props.variant === "homepage" ? "-200px" : "0")};
  z-index: ${(props) => (props.variant === "homepage" ? "99" : "0")};
  @media (max-width: 576px) {
    margin-top: ${(props) => (props.variant === "homepage" ? "-100px" : "0")};
  }
  @media (max-width: 400px) {
    margin-top: ${(props) => (props.variant === "homepage" ? "-50px" : "0")};
  }
`

export const Section = styled.section`
  background: linear-gradient(
    270deg,
    ${color.oxfordBlue[900]} 0%,
    ${color.common.gradientBlue} 100%
  );
  width: 100%;
  padding: 102px 15px;
  ${(props) =>
    props?.background && props?.background === `lightgradient`
      ? css`
          background: linear-gradient(
              180deg,
              ${color.common.white} 71.87%,
              rgba(255, 255, 255, 0) 99.99%,
              rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(
              250.72deg,
              #abe2f4 -8.68%,
              #f0f7e9 62.92%,
              #edfbff 117.43%
            );
        `
      : props?.background === `light`
      ? css`
          background: ${color.aliceBlue[50]};
        `
      : css`
          background: linear-gradient(
            270deg,
            ${color.oxfordBlue[900]} 0%,
            ${color.common.gradientBlue} 100%
          );
        `}
`

export const Grid = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1170px;
  @media (max-width: 1199.98px) {
    max-width: 992px;
  }
  @media (max-width: 991.98px) {
    max-width: 770px;
  }
  @media (max-width: 767.98px) {
    max-width: 506px;
  }
`
export const HeadingWrapper = styled.div`
  margin: 0 auto;
  max-width: 870px;
`

export const Heading = styled.div`
  font-weight: 900;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${color.common.white};
  text-align: center;
  margin-bottom: 16px;
  ${(props) =>
    props?.background && props?.background === `lightgradient`
      ? css`
          color: ${color.oxfordBlue[900]};
        `
      : props?.background === `light`
      ? css`
          color: ${color.oxfordBlue[900]};
        `
      : css`
          color: ${color.common.white};
        `}
  @media (max-width: 767.98px) {
    font-size: 36px;
    line-height: 44px;
  }
  @media (max-width: 575.98px) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const Subhead = styled.div`
  font-weight: 350;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: ${color.copy[200]};
  ${(props) =>
    props?.background && props?.background === `lightgradient`
      ? css`
          color: ${color.copy[700]};
        `
      : props?.background === `light`
      ? css`
          color: ${color.copy[700]};
        `
      : css`
          color: ${color.copy[200]};
        `}
  @media (max-width: 575.98px) {
    font-size: 16px;
  }
`

export const SwitchbackContainer = styled.div`
  display: flex;
  margin-top: 80px;
  justify-content: space-between;
  @media (max-width: 991.98px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 64px;
  }
  .animation {
    height: auto;
    width: 100%;
    max-width: 600px;
  }
`

export const SwitchbackImg = styled.img`
  max-height: fit-content;
  height: auto;
  width: 100%;
  margin: auto;
  max-width: 600px;
`

export const Curves = styled.img`
  height: auto;
  width: 100%;
  &.top {
    margin-bottom: -1px;
  }
  &.bottom {
    margin-top: -1px;
  }
`
