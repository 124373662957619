import React from "react"
import { StructuredText } from "react-datocms"

import {
  Container,
  SecondaryWrapper,
  Heading,
  Subhead,
  ItemGrid,
  Item,
  IconWrapper,
} from "../styles/SwitchbackContent.styled"

const SwitchbackContent = ({ component }) => {
  const items = component?.multicolumnItems
  const background = component?.background
  const bgClass = background && background.replace(/ /g, "").toLowerCase()

  return (
    <Container>
      <SecondaryWrapper>
        {component?.secondaryHeading && (
          <Heading background={bgClass}>{component?.secondaryHeading}</Heading>
        )}
        {component?.secondarySubhead && (
          <Subhead background={bgClass}>{component?.secondarySubhead}</Subhead>
        )}
      </SecondaryWrapper>
      <ItemGrid>
        {items.length > 0 &&
          items.map((item) => (
            <Item key={item?.id} background={bgClass}>
              {item?.icon?.url && (
                <IconWrapper>
                  <img src={item?.icon?.url} alt={item?.icon?.alt || "Icon"} />
                </IconWrapper>
              )}
              <p className="label">{item?.label}</p>
              {item?.description && <StructuredText data={item?.description} />}
            </Item>
          ))}
      </ItemGrid>
    </Container>
  )
}

export default SwitchbackContent
